import { useContext } from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi'
import { motion } from 'framer-motion';
import ContextStates from '../context/ContextStates'
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animaciones';
import { Link } from 'react-router-dom';
import logo from '../assets/logoSm.png'

const Footer = () => {
    const { lang, setLang } = useContext(ContextStates);
    const chLang = () => {
        if (lang === 'ES')
            setLang("EN")
        else
            setLang("ES")
    }

    const today = new Date();
    const year = today.getFullYear();


    return (
        <motion.footer className="bg-slate-50 text-center lg:text-left text-black font-sans"
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.1 }}
            variants={areaAnimateS}
        >
            <div className="flex justify-center items-center lg:justify-between p-6 border-b border-gray-100">
                <div className="mr-12 hidden lg:block">
                    <span>{lang === "EN" ? 'Contáctanos' : 'Contact us'}</span>
                </div>
                <div className="flex justify-center">
                    <a
                        href="https://wa.me/543816558896"
                        className="mr-6 text-black"
                        target={"_blank"}
                    >
                        <FaWhatsapp />
                    </a>
                </div>
            </div>
            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <motion.div
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{ once: true, amount: 0.1 }}
                        variants={areaAnimateLR}
                    >
                        <Link to="/">
                            <div className='w-10/12 mx-auto'>
                                <img src={logo} className='object-cover' />
                            </div>
                        </Link>
                    </motion.div>
                    <motion.div
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{ once: true, amount: 0.1 }}
                        variants={areaAnimateLR}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            {lang === "EN" ? 'Nuestro Software' : 'Our Software'}
                        </h6>
                        <p className="mb-4">
                            <Link to="/softcmms" className="text-black">CMMS</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="/" className="text-black">E-Commerce</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="/" className="text-black">{lang === "EN" ? 'Páginas Web a medida' : 'Customized websites.'}</Link>
                        </p>
                    </motion.div>
                    <motion.div
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{ once: true, amount: 0.1 }}
                        variants={areaAnimateRL}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            {lang === "EN" ? 'Enlaces' : 'Links'}
                        </h6>

                        <p className="mb-4">
                            <Link to="/questions" className="text-black">{lang === "EN" ? 'Preguntas' : 'Questions'}</Link>
                        </p>
                        <p>
                            <Link to="/aboutus" className="text-black">{lang === "EN" ? 'Nosotros' : 'About Us'}</Link>
                        </p>
                    </motion.div>
                    <motion.div
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{ once: true, amount: 0.1 }}
                        variants={areaAnimateRL}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            {lang === "EN" ? 'Contacto' : 'Contact Us'}
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlineMail className='mr-1' />
                            info@mirx.com.ar
                        </p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlinePhone className='mr-1' />
                            + 54 381 655-8896
                        </p>
                        <a
                            className="flex items-center justify-center md:justify-start mb-4"
                            href='https://wa.me/543816558896'
                            target={"_blank"}
                            rel='noreferrer'
                        >
                            <FaWhatsapp className="mr-1" aria-hidden="true" />
                            + 54 381 6558896
                        </a>
                        <motion.div
                            className='w-full sm:w-1/4 mt-5'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimateRL}
                        >
                            {lang === "EN" ? (<button className='text-sm' type='button' onClick={() => chLang()}>Lang: EN</button>) : (<button type='button' onClick={() => chLang()}>Idioma: ES</button>)}
                        </motion.div>
                    </motion.div>

                </div>
            </div>
            <motion.div
                className="text-center p-6"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={areaAnimateRL}
            >
                <span>© {year} Copyright:</span>
                <Link className="text-black font-semibold" to="/">Mirx Software</Link>
            </motion.div>
        </motion.footer>
    )
}

export default Footer